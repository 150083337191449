import './App.css';

function App() {
  return (
    <>
      <header>
        <h1>travis<br></br>
          JS, Java, Solidity, C++<br></br>
          instagram.com/_trvs<br></br>
          ✱
        </h1>
      </header>
    </>
  );
}

export default App;
